#fsbodiv {

  > table {
    border: 0;
    width: 100%;

    tr {
      td, th {
        &:first-of-type:before {
          content: '\00a0\00a0';
        }
      }
      td {
        &:first-of-type:after {
          content: '\00a0';
        }
      }
    }

    tbody {

      // @NOTE Groups of 2 [rows]
      tr:nth-child(2n-1) { // @NOTE First row
        td:not(:first-of-type):not(:last-of-type) {
          padding: 10px 0 2px 0;
        }
      }
      tr:nth-child(2n) { // @NOTE Second row
        td {
          padding: 2px 0 10px 0;
        }
      }

      // @NOTE Groups of 4 [rows]
      tr:nth-child(4n-3), // @NOTE First row
      tr:nth-child(4n-2) { // @NOTE Second row
        background-color: #d3ddea;
      }
      tr:nth-child(4n-1), // @NOTE Third row
      tr:nth-child(4n) { // @NOTE Fourth row
        background-color: #ffffff;
      }
    }
  }
}
