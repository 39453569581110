label {

  span {

    &.left:after {
      content: '\00a0';
    }

    &.right:before {
      content: '\00a0';
    }
  }
}

button.option {
  border: none;
  background-color: inherit;
  padding: 14px auto;
  font-size: 15px;
  cursor: pointer;
  display: inline-block;
  color: blue;
  border-bottom: 2px solid rgba(0,0,0,0);

  &:hover, &.selected {
    border-bottom: 2px solid blue;
  }
}
